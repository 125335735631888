<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      class="pa-0 pa-md-4 mb-4"
    >
      <div class="text-h6 text-white font-weight-bold">Kontakt</div>
      <ul>
        <li>
          <NuxtLink to="/contact">Kontakt</NuxtLink>
        </li>
        <!-- <li><a href="#">Support</a></li> -->
        <li>
          <NuxtLink to="/faq">FAQ</NuxtLink>
        </li>

        <li>
          <NuxtLink to="/katalog">Geschäfte in Ihrer Nähe</NuxtLink>
        </li>
        <!-- <li><a href="#">Blog</a></li> -->
      </ul>
    </v-col>
    <!-- <v-col
      cols="12"
      md="3"
      class="pa-0 pa-md-4 mb-4"
    >
      <div class="text-h6 text-white font-weight-bold">Unser Angebot</div>
      <ul>
        <li><a href="#">Terminplaner</a></li>
        <li><a href="#">Kundenverwaltung</a></li>
        <li><a href="#">Personalplanung</a></li>
      </ul>
    </v-col> -->
    <v-col
      cols="12"
      md="4"
      class="pa-0 pa-md-4 mb-4"
    >
      <div class="text-h6 text-white font-weight-bold">Geschäftspartner</div>
      <ul>
        <li>
          <NuxtLink to="/auth/register">Partner werden</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/auth/login">Partner-Login</NuxtLink>
        </li>
      </ul>
    </v-col>
    <v-col
      cols="12"
      md="4"
      class="pa-0 pa-md-4"
    >
      <div class="text-h6 text-white font-weight-bold">Rechtliches</div>
      <ul>
        <li>
          <NuxtLink to="/legal/impressum">Impressum</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/legal/data-protection">Datenschutz</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/legal/agb">AGB</NuxtLink>
        </li>
      </ul>
    </v-col>
    <v-divider
      color="white"
      :thickness="4"
    />

    <v-col cols="12">
      <div class="h-100 d-flex align-center justify-md-space-between justify-center">
        <!-- 👉 Footer: left content -->
        <div class="d-flex align-center text-white flex-column flex-md-row">
          <span class="d-block d-md-inline">&copy; {{ new Date().getFullYear() }} TerminTiger&nbsp;</span>
          <span class="d-block d-md-inline"><a
              href="https://fribu.de"
              target="_blank"
              class="ms-1 text-white"
            >Fribu - Smart Solutions UG</a>
          </span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
ul {
  list-style: none;

  a {
    color: rgb(var(--v-theme-surface));

    &:hover {
      color: rgb(var(--v-theme-secondary));
    }
  }

  li {
    margin-bottom: 0.5rem;
  }
}

h5 {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.router-link-exact-active {
  color: rgb(var(--v-theme-secondary));
}
</style>